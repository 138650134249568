.auth-comp {
    display: flex;
    align-items: center;
}

.auth-header { 
    width: 50%;
    margin: auto;
}


.auth-logo {
    width: 26%;
    margin-bottom: 30px;
    padding-left: 2%;
}

.auth-register {
    text-decoration: underline;
    cursor: pointer;
    padding-left: 5%;
    margin-top: 20px;
}


.auth-form {
    width: 80%;
    padding: 0% 5% 0% 5%;
}

.auth-button {
    width: 50%
}

.auth-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
}

.auth-forgot { 
    color: #808080;
    text-decoration: none;
}

.auth-vector {
    width: 50%;
    background-color: #ecf3fa;
    height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.auth-vector img {
    width: 90%;
    padding: 20px;
    margin: auto;
}

.form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3%;
}

