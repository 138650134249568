.nav-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #808080;
    z-index:1000;
}

.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: left;
}

.nav-links li {
    padding: 2rem;
    padding-bottom: 1rem;

}

.nav-links a {
    border: 1px solid transparent;
    color: #808080;
    text-decoration: none;
    padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
    background: #deebf9;
    color: #000;
}


.nav-links button:hover,
.nav-links button:active {
    background: #292929;
    color: white;
}